<template>
  <div>
    <v-container class="maincontainer">
      <ArticleView :pageitems="pageitems" :pagetitle="pagetitle" />
    </v-container>
    <v-dialog
      fullscreen
      v-if="detaildialogtext && detaildialog && detaildialogtext.trim()"
      v-model="detaildialog"
    >
      <v-card>
        <v-card-title class="headline pb-3 justify-space-between">
          <!-- Get References -->
          <v-btn
            class="close-modal-btn"
            color="primary"
            fab
            x-small
            dark
            @click="
              detaildialog = false;
              detaildialogtext = '';
            "
            aria-label="Ref Getter"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-container>
            <v-md-preview :text="detaildialogtext" />
          </v-container>
          <!-- <AssistedGrading :questionindex="activeQuestionIndex" /> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get } from "@/apiClient";
import ArticleView from "@/components/ArticleView.vue";
export default {
  components: {
    ArticleView,
  },
  data() {
    return {
      pagetitle: "",
      pageitems: [],
      detaildialog: false,
      detaildialogtext: "",
    };
  },
  mounted() {
    let route = this.$route;
    get(`/articles/${process.env.VUE_APP_HOME_ARTICLE}`)
      .then((response) => {
        // console.log(response);
        this.pagetitle = response.data.title;
        this.pageitems = response.data.items;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    article() {
      let route = this.$route;
      return route.params.id;
    },
  },
  methods: {
    detailDialog: function (text) {
      this.detaildialogtext = text;
      this.detaildialog = true;
    },
  },
};
</script>

<style scoped>
.maincontainer {
padding-top: 80px;
}
</style>