<template>
  <div>
    <h1 class="mb-10" v-if="pagetitle">{{ pagetitle }}</h1>
    <div v-for="(itm, i) of pageitems" :key="i" class="mb-2">
      <!-- <Markdown
          v-if="itm.type === 'text'"
          :breaks="true"
          :html="true"
          :source="cleanText(itm.value)"
        /> -->
      <div v-if="itm.type === 'card'">
        <div class="github-markdown-body">
          <v-row>
            <v-col
              v-for="card of itm.value"
              :key="card.title"
              :cols="12"
              :sm="itm.widthsm"
              :md="itm.widthmd"
              :lg="itm.widthlg"
            >
              <v-card class="mx-auto height-tbv" :variant="itm.style">
                <v-card-item>
                  <div>
                    <div class="text-overline mb-1"></div>
                    <div class="text-h6 mb-1">{{ card.header }}</div>
                    <div class="text-caption">
                      
                      <v-md-preview :text="card.content" />
                    </div>
                  </div>
                </v-card-item>
                <v-card-actions v-if="card.link" class="pl-4 pr-4 pb-4">
                  <v-btn
                    v-if="card.link"
                    variant="outlined"
                    color="black"
                    :href="card.link"
                    :target="card.linknewwindow ? '_blank' : '_self'"
                  >
                    Read
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="itm.type === 'text'">
        <v-md-preview v-if="!itm.collapsed" :text="itm.value" />
        <div v-else>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-title>
                <div class="d-flex align-center justify-space-between">
                  <h3>
                    {{ itm.title }}
                  </h3>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-md-preview :text="itm.value" />
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

      <h3 v-if="itm.type === 'timeline'" class="mt-10 mb-10">
        {{ itm.title }}
      </h3>
      <v-timeline
        v-if="itm.type === 'timeline'"
        class="mt-10 mb-10"
        align="start"
        side="end"
      >
        <v-timeline-item
          v-for="(item, index) in itm.value"
          :key="index"
          color="primary"
        >
          <template v-slot:opposite>
            <div
              :class="`pt-1 headline font-weight-bold `"
              v-text="item.year"
            ></div>
          </template>
          <div style="max-width: 400px">
            <h3 :class="`mt-n1 headline mb-3 `">
              {{ item.title }}
            </h3>
            <div>
              <!-- <Markdown :breaks="true" :html="true" :source="item.content" /> -->
              <v-md-preview style="padding: 0px" :text="item.content" />
            </div>
            <div class="mt-2" v-if="item.sourcerating">
              <v-rating :model-value="item.sourcerating" readonly />
            </div>
            <div>
              <v-btn
                class="mr-2"
                variant="outlined"
                color="black"
                v-if="item.detailcontent.trim()"
                @click="detailDialog(item.detailcontent.trim())"
              >
                Detail
              </v-btn>
              <!-- <v-btn variant="outlined" color="primary"> Check </v-btn> -->
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
      <!-- <Markdown :breaks="true" :html="true" :source="bottomtext" /> -->
    </div>
    <v-dialog
      fullscreen
      v-if="detaildialogtext && detaildialog && detaildialogtext.trim()"
      v-model="detaildialog"
    >
      <v-card>
        <v-card-title class="headline pb-3 justify-space-between">
          <!-- Get References -->
          <v-btn
            class="close-modal-btn"
            color="black"
            fab
            x-small
            dark
            @click="
              detaildialog = false;
              detaildialogtext = '';
            "
            aria-label="Ref Getter"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-container>
            <v-md-preview :text="detaildialogtext" />
          </v-container>
          <!-- <AssistedGrading :questionindex="activeQuestionIndex" /> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    pageitems: {
      type: Array,
      default: () => [],
    },
    pagetitle: {
      type: String,
      default: "",
    },
    bottomtext: {
      type: String,
      default: "",
    },
  },
  methods: {
    cleanText: function (text) {
      return text.replace(/<[^>]*>?/gm, "");
    },
    detailDialog: function (text) {
      this.detaildialogtext = text;
      this.detaildialog = true;
    },
  },
  data: () => ({
    detaildialog: false,
    detaildialogtext: "",
  }),
};
</script>

<style>
</style>